import { when } from 'k-ramel'
import {
  load,
  review,
  next,
  previous,
  goToEdit,
  remove,
} from './review.reactions'

export default [
  when('@@krml/LISTENERS>ADDED>review')(load),
  when('@@router/ROUTE_FOUND', ({ payload }) => payload.route.code === 'review')(load),

  when('@@ui/BUTTON>REFUSE_CONFIRM')(review(false)),
  when('@@ui/BUTTON>ACCEPT_EXPENSE')(review(true)),

  when('@@ui/BUTTON>EDIT_EXPENSE')(goToEdit),
  when('@@ui/BUTTON>REMOVE_EXPENSE')(remove),

  when(/EXPENSE>PREVIOUS/)(previous),
  when(/EXPENSE>NEXT/)(next(false)),
  when('@@ui/BUTTON>REMOVE_EXPENSE')(next(true)),
]
