import { container } from '@k-mille/ui-components'
import Component from './actions'

const mapStore = (store, ownProps, { form, router }) => {
  const id = router.getParam('id')
  const expense = form(id).get()
  const creator = form(id).get('creator')
  const { email } = store.data.profile.info.get()
  return {
    ...expense,
    isOwner: creator.email === email,
    onClickRefuse: () => store.ui.modalId.set('REFUSE_EXPENSE'),
  }
}

export default container({
  mapStore,
  loader: 'id',
  intl: 'expense',
})(Component)
