import React from 'react'
import PropTypes from 'prop-types'
import {
  component,
  Modal,
  Button,
  Input,
} from '@k-mille/ui-components'
import styles from './refuseModal.styles'


const RefuseModal = ({
  classes,
  messages,
  expenseId,
  onClickCancel,
}) => (
  <Modal
    className={classes.main}
    id="REFUSE_EXPENSE"
  >
    <Modal.Header>
      {messages.title}
    </Modal.Header>
    <Modal.Content
      className={classes.content}
    >
      <Input
        description={messages.content}
        placeholder={messages.description}
        type="textarea"
        name="refuseDescription"
        formName={expenseId}
      />
    </Modal.Content>
    <Modal.Actions>
      <Button
        primary
        id={expenseId}
        name="REFUSE_CONFIRM"
      >
        {messages.confirm}
      </Button>
      <Button
        secondary
        name="REFUSE_CANCEL"
        onClick={onClickCancel}
      >
        {messages.cancel}
      </Button>
    </Modal.Actions>
  </Modal>
)

RefuseModal.propTypes = {
  classes: PropTypes.object.isRequired,
  messages: PropTypes.object.isRequired,
  expenseId: PropTypes.string.isRequired,
  onClickCancel: PropTypes.func.isRequired,
}

export default component({ styles })(RefuseModal)
