export default theme => ({
  main: {
    gridArea: 'nav',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderTop: `solid 1px ${theme.lightGrey}`,
    background: theme.background,
  },
  input: {
    display: 'none',
  },
})
