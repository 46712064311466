import { types } from 'k-ramel'

export default {
  data: {
    clients: types.keyValue(),
    users: types.keyValue(),
    expenses: types.keyValue(),
  },
  ui: {
    reviews: types.keyValue(),
    editStep: types.number(),
  },
}
