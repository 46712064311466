import React from 'react'
import PropTypes from 'prop-types'
import { component } from '@k-mille/ui-components'
import styles from './prices.styles'

const Prices = ({
  classes,
  messages,
}) => (
  <div className={classes.main}>
    <div className={classes.total}>
      {messages.total}
    </div>
    <div className={classes.separator} />
    <div className={classes.taxesRatio}>
      {messages.taxesRatio}
    </div>
  </div>
)

Prices.propTypes = {
  classes: PropTypes.object.isRequired,
  messages: PropTypes.object.isRequired,
}

export default component({ styles })(Prices)
