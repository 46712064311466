import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { component, Button, List } from '@k-mille/ui-components'
import ExpenseActions from './actions'
import styles from './expense.styles'

const Expense = ({
  classes,
  messages,
  id,
  client,
  creator,
  dates,
  document,
  draft,
  isAccepted,
  user,
  type,
}) => (
  <List.Item
    className={cn(
      classes.main,
      {
        [classes.draft]: !!draft,
        [classes.notAccepted]: isAccepted === false,
      },
    )}
  >
    <div className={classes.content}>
      <a
        href={document.url}
        className={classes.image}
        rel="noopener noreferrer"
        target="_blank"
      >
        {document.type === 'application/pdf' ? (
          <object
            data={document.url}
            type="application/pdf"
            className={classes.document}
          >
            <Button
              className={classes.pdfButton}
              icon="pdf"
              name="expense_download_pdf"
              href={document.url}
            />
          </object>
        ) : (
          <img
            src={document.url}
            alt="document"
            className={classes.document}
          />
        )}
      </a>
      <div className={classes.info}>
        <div>
          {new Date(dates.create).toLocaleDateString()}
        </div>
        <div className={classes.context}>
          {messages[type] || type}
          {user && (
            <>
              {user.firstName}
              {' '}
              {user.name}
            </>
          )}
          {client && client.name}
        </div>

        <div>
          {messages.total}
        </div>

        <div className={classes.creator}>
          {creator.firstName}
          {' '}
          {creator.name}
        </div>
      </div>
    </div>
    <ExpenseActions id={id} />
  </List.Item>
)

Expense.propTypes = {
  classes: PropTypes.object,
  messages: PropTypes.object,
  id: PropTypes.string,
  client: PropTypes.shape({
    name: PropTypes.string,
  }),
  creator: PropTypes.shape({
    name: PropTypes.string,
    firstName: PropTypes.string,
  }),
  dates: PropTypes.shape({
    create: PropTypes.number,
  }),
  document: PropTypes.shape({
    url: PropTypes.string,
    type: PropTypes.string,
  }),
  draft: PropTypes.bool,
  isAccepted: PropTypes.bool,
  prices: PropTypes.shape({
    total: PropTypes.number,
  }),
  type: PropTypes.string,
  user: PropTypes.shape({
    name: PropTypes.string,
    firstName: PropTypes.string,
  }),
}

Expense.defaultProps = {
  classes: {},
  messages: {},
  id: undefined,
  client: {},
  creator: {},
  dates: {},
  document: {},
  draft: false,
  isAccepted: false,
  prices: {},
  type: undefined,
  user: {},
}

export default component({ styles })(Expense)
