export default theme => ({
  main: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.background,
    border: `solid 1px ${theme.warning}`,
    color: theme.warning,
    borderRadius: '4px',
    padding: '.5em',
    position: 'absolute',
    bottom: '1em',
    margin: '0 1em',
    transition: 'opacity .25s linear',
    opacity: 0.9,
    '&:hover': {
      opacity: 0.2,
    },
  },
})
