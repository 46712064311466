import { container } from '@k-mille/ui-components'
import listeners from './edit.listeners'
import Component from './edit'

const name = 'edit'

const mapStore = (store, ownProps, { router }) => ({
  id: router.getParam('id'),
})

export default container({
  name,
  isScreen: true,
  mapStore,
  listeners,
})(Component)
