import {
  getExpense,
  getClients,
  getUsers,
  replaceExpense,
  removeExpense,
} from './edit.operations'

export const load = (action, store, { router, form, graphql }) => {
  const id = router.getParam('id')
  const queries = []
  if (!store.data.clients.isInitialized()) queries.push(getClients())
  if (!store.data.users.isInitialized()) queries.push(getUsers())
  if (!form(id).get('id')) queries.push(getExpense(id))

  if (queries.length > 0) graphql.query(queries.join(' '))
  store.ui.editStep.set(0)
}

export const setClients = ({ payload }, store) => {
  store.data.clients.add(payload)
}

export const setUsers = ({ payload }, store) => {
  store.data.users.add(payload)
}

export const remove = (action, store, { router, form, graphql }) => {
  const id = router.getParam('id')
  graphql.mutation(removeExpense(id))
  form(id).reset()
}

export const save = (action, store, { router, form, graphql }) => {
  const expenseId = router.getParam('id')
  const {
    id,
    clients,
    total,
    taxesRatio,
    needRefund = false,
  } = form(expenseId).get()

  // Type of expense client may be client or user
  const [type, idType] = (clients && clients.split('-')) || []

  graphql.mutation(
    replaceExpense(),
    {
      expense: {
        id,
        ...(type && {
          [type]: {
            id: idType,
          },
        }),
        prices: {
          total,
          taxesRatio,
        },
        needRefund,
      },
    },
  )
}

export const goToReviews = (action, store, { router }) => {
  const queue = [...store.ui.reviews.getAsArray()]
  queue.shift()
  store.ui.reviews.set(queue)
  router.push('reviews')
}

export const nextStep = ({ payload }, store) => {
  const { id } = payload
  store.ui.editStep.set(id + 1)
}

export const previousStep = ({ payload }, store) => {
  const { id } = payload
  store.ui.editStep.set(id - 1)
}
