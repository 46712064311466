import { container } from '@k-mille/ui-components'
import Component from './refuseDescription'

const mapStore = (store, { id }, { form }) => {
  const { refuseDescription } = form(id).get()
  return {
    refuseDescription,
  }
}

export default container({
  mapStore,
})(Component)
