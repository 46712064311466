import React from 'react'
import PropTypes from 'prop-types'
import { component, Button, List } from '@k-mille/ui-components'
import styles from './actions.styles'

const ExpenseActions = ({
  classes,
  id,
  isOwner,
}) => (
  <List.Item.Actions
    className={classes.main}
  >

    {isOwner && (
      <Button
        icon="edit"
        id={id}
        toRouteCode="edit"
      />
    )}
  </List.Item.Actions>
)

ExpenseActions.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  isOwner: PropTypes.bool,
}

ExpenseActions.defaultProps = {
  isOwner: false,
}

export default component({ styles })(ExpenseActions)
