import { container } from '@k-mille/ui-components'
import Component from './refuseModal'

const intl = 'reviews.refuse'

const mapStore = (store, ownProps, { router }) => ({
  expenseId: router.getParam('id'),
  onClickCancel: () => store.ui.modalId.reset(),
})

export default container({
  mapStore,
  intl,
})(Component)
