import { container } from '@k-mille/ui-components'
import Component from './info'

const intl = 'expense'

const mapStore = (store, { id }, { form }) => form(id).get()

export default container({
  mapStore,
  intl,
})(Component)
