export default {
  main: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
  icon: {
    '& > i': {
      fontSize: '1.5em',
    },
  },
}
