import { container } from '@k-mille/ui-components'
import Component from './creator'

const intl = 'expense'

const mapStore = (store, { id }, { form }) => {
  const { creator, dates, needRefund } = form(id).get()
  return {
    ...creator,
    dates,
    needRefund,
  }
}

export default container({
  mapStore,
  intl,
})(Component)
