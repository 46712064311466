import { container } from '@k-mille/ui-components'
import LoadingIndicator from './expense.loading'
import Component from './expense'

const mapStore = (store, { id }, { form }) => ({
  ...form(id).get(),
})

export default container({
  mapStore,
  loader: 'dates', // dates is a required value to save an expense
  LoadingIndicator,
})(Component)
