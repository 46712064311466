import React from 'react'
import PropTypes from 'prop-types'
import { component, Input } from '@k-mille/ui-components'
import styles from './total.styles'

const Total = ({
  classes,
  messages,
  expenseId,
  onKeyPress,
}) => (
  <div className={classes.main}>
    <Input
      className={classes.main}
      filled
      type="number"
      formName={expenseId}
      label={messages.price}
      name="total"
      autoFocus
      onKeyPress={onKeyPress}
    />
  </div>
)

Total.propTypes = {
  classes: PropTypes.object.isRequired,
  messages: PropTypes.object.isRequired,
  onKeyPress: PropTypes.func.isRequired,
  expenseId: PropTypes.string,
}

Total.defaultProps = {
  expenseId: undefined,
}

export default component({ styles })(Total)
