import React from 'react'
import PropTypes from 'prop-types'
import { component, Button } from '@k-mille/ui-components'
import styles from './actions.styles'

const ExpenseActions = ({
  classes,
  messages,
  id,
}) => (
  <div
    className={classes.main}
  >
    <Button
      id={id}
      name="REMOVE_EXPENSE"
    >
      {messages.remove}
    </Button>
    <Button
      id={id}
      name="SAVE_EXPENSE"
    >
      {messages.save}
    </Button>
  </div>
)

ExpenseActions.propTypes = {
  classes: PropTypes.object.isRequired,
  messages: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
}

export default component({ styles })(ExpenseActions)
