import { when } from 'k-ramel'
import {
  load,
  loadMore,
  set,
  add,
} from './expenses.reactions'

export default [
  when('@@krml/LISTENERS>ADDED>expenses')(load),
  when('@@ui/SCROLL_END')(loadMore),
  when('@@graphql/lastExpenses>ENDED')(set),
  when('@@graphql/moreExpenses>ENDED')(add),
]
