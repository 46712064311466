import { container } from '@k-mille/ui-components'
import Component from './reviews'
import listeners from './reviews.listeners'

const mapStore = store => ({
  haveNext: store.ui.reviews.getLength() > 1,
  allDone: store.ui.reviews.getLength() === 0,
})

export default container({
  name: 'reviews',
  intl: 'reviews',
  isScreen: { relative: true },
  mapStore,
  listeners,
})(Component)
