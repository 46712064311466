export default {
  main: {
  },
  primary: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  secondary: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  input: {
    margin: '.5em',
  },
  users: {
    display: ({ type }) => (type === 'USER' ? 'block' : 'none'),
  },
  clients: {
    display: ({ type }) => (type === 'CLIENT' ? 'block' : 'none'),
  },
}
