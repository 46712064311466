import { when } from 'k-ramel'
import {
  load,
  setClients,
  setUsers,
  save,
  remove,
  goToReviews,
  nextStep,
  previousStep,
} from './edit.reactions'

export default [
  when('@@krml/LISTENERS>ADDED>edit')(load),
  when('@@graphql/clients>ENDED')(setClients),
  when('@@graphql/users>ENDED')(setUsers),
  when('@@ui/BUTTON>SAVE_EXPENSE')(save),
  when('@@ui/BUTTON>REMOVE_EXPENSE')(remove),
  when('@@graphql/replaceExpense>ENDED')(goToReviews),
  when('@@graphql/removeExpense>ENDED')(goToReviews),
  when(/NEXT_EDIT_STEP/)(nextStep),
  when('@@ui/BUTTON>PREVIOUS_EDIT_STEP')(previousStep),
]
