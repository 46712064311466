import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { component, Button } from '@k-mille/ui-components'
import Clients from './clients'
import Price from './total'
import Taxes from './taxes'
import NeedRefund from './needRefund'
import styles from './edit.styles'

const getStepComponent = (step) => {
  switch (step) {
    case 0: return Clients
    case 1: return Price
    case 2: return Taxes
    case 3: return NeedRefund
    default: return Clients
  }
}

const Edit = ({
  classes,
  className,
  id,
  step,
  onSubmit,
}) => (
  <div
    className={cn(className, classes.main)}
    onSubmit={onSubmit}
  >
    <Button className={classes.icon} name="PREVIOUS_EDIT_STEP" id={step} icon="previous" disabled={step <= 0} />
    {React.createElement(getStepComponent(step), { expenseId: id })}
    <Button className={classes.icon} name="NEXT_EDIT_STEP" id={step} icon="next" disabled={step >= 3} />
  </div>
)

Edit.propTypes = {
  classes: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  className: PropTypes.string,
  id: PropTypes.string,
  step: PropTypes.number,
}

Edit.defaultProps = {
  className: undefined,
  id: undefined,
  step: 0,
}

export default component({ styles })(Edit)
