export default {
  main: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    position: 'fixed',
  },
  list: {
    marginTop: '6em',
  },
}
