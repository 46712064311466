import { container } from '@k-mille/ui-components'
import Component from './edit'

const mapStore = store => ({
  step: store.ui.editStep.get(),
  onSubmit: () => store.dispatch({ type: '@@ui/BUTTON>SAVE>EXPENSE' }),
})

export default container({
  mapStore,
})(Component)
