import { container } from '@k-mille/ui-components'
import Component from './user'

const mapStore = (store, { id }, { form }) => {
  const [, userId] = form(id).get('clients').split('-')
  return store.data.users.get(userId)
}

export default container({
  mapStore,
})(Component)
