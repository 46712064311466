import { container } from '@k-mille/ui-components'
import Component from './expense'

const mapStore = (store, { id }) => {
  const expense = store.data.expenses.get(id)

  return {
    ...expense,
    total: expense.prices.total,
  }
}

export default container({
  intl: 'expenses',
  mapStore,
})(Component)
