import { container } from '@k-mille/ui-components'
import Component from './clients'

const mapStore = (store, { expenseId }, { form }) => ({
  project: store.data.profile.project.get(),
  clients: store.data.clients.getAsArray(),
  users: store.data.users.getAsArray(),
  type: form(expenseId).get('type'),
  onClick: () => store.dispatch({ type: '@@ui>NEXT_EDIT_STEP', payload: { id: 0 } }),
})

export default container({
  mapStore,
})(Component)
