import { container } from '@k-mille/ui-components'
import Component from './review'
import listeners from './review.listeners'

const name = 'review'

const mapStore = (store, ownProps, { router }) => {
  const id = router.getParam('id')

  return {
    id,
    onSwipedLeft: e => store.dispatch({ type: '@@ui>EXPENSE>PREVIOUS', payload: e }),
    onSwipedRight: e => store.dispatch({ type: '@@ui>EXPENSE>NEXT', payload: e }),
  }
}

export default container({
  name,
  isScreen: true,
  listeners,
  mapStore,
})(Component)
