import { container } from '@k-mille/ui-components'
import Component from './client'

const mapStore = (store, { id }, { form }) => {
  const [, clientId] = form(id).get('clients').split('-')
  return store.data.clients.get(clientId)
}

export default container({
  mapStore,
})(Component)
