export default {
  main: {
    position: 'absolute',
    width: '100%',
    height: ({ isPDF }) => (isPDF ? '100%' : 'auto'),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}
