import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { component, Button } from '@k-mille/ui-components'
import Header from '../../header'
import RefuseModal from './refuseModal'
import Review from './review'
import Actions from './actions'
import Nav from './nav'
import styles from './reviews.styles'

const Reviews = ({
  classes,
  messages,
  haveNext,
  allDone,
}) => (
  <div className={classes.main}>
    <Header />
    {allDone || (
      <Fragment>
        <div className={classes.content}>
          {haveNext && <Button className={classes.next} name="EXPENSE>PREVIOUS" icon="previous" />}
          <Review />
          {haveNext && <Button className={classes.next} name="EXPENSE>NEXT" icon="next" />}
        </div>
        <Actions />
      </Fragment>
    )}
    {allDone && <div className={classes.allDone}>{messages.allDone}</div>}
    <Nav />
    <RefuseModal />
  </div>
)

Reviews.propTypes = {
  classes: PropTypes.object.isRequired,
  messages: PropTypes.object.isRequired,
  haveNext: PropTypes.bool,
  allDone: PropTypes.bool,
}

Reviews.defaultProps = {
  haveNext: false,
  allDone: false,
}

export default component({ styles })(Reviews)
