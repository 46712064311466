export default theme => ({
  main: {
    gridArea: 'header',
    display: 'grid',
    alignItems: 'center',
    padding: '1em 2em',
    height: '4em',
    zIndex: '10',
    backgroundColor: theme.background,
    width: 'calc(100vw - 4em)',
    boxShadow: `0px -5px 10px ${theme.darkFont}`,
    gridTemplateColumns: '1fr auto 1fr',
    gridTemplateRows: '4em',
    gridTemplateAreas: '". title links"',
  },
  title: {
    gridArea: 'title',
    width: 'calc(100% - 4em)',
    textAlign: 'center',
  },
  links: {
    gridArea: 'links',
    display: 'flex',
    marginLeft: 'auto',
  },
})
