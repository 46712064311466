/* eslint-disable import/prefer-default-export */
const EXPENSE_FIELDS = `
  id
  type
  draft
  isAccepted
  refuseDescription
  needRefund
  client {
    name
  }
  user {
    name
    firstName
  }
  document {
    url
    type
  }
  dates {
    create
  }
  creator {
    name
    firstName
    email
  }
  prices {
    total
  }
`

export const lastExpenses = () => `{
  lastExpenses: expenses {
    ${EXPENSE_FIELDS}
  }
}`

export const moreExpenses = (limit, lastId) => `{
  moreExpenses: expenses(limit: ${limit} ${lastId ? `, lastId: "${lastId}"` : ''}) {
    ${EXPENSE_FIELDS}
  }
}`
