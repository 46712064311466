import { container } from '@k-mille/ui-components'
import Component from './taxesRatio'

const mapStore = store => ({
  onClick: () => store.dispatch({ type: '@@ui>NEXT_EDIT_STEP', payload: { id: 2 } }),
})

export default container({
  mapStore,
  intl: 'expense',
})(Component)
