export default {
  price: {
    display: 'flex',
    alignItems: 'flex-end',
    flexWrap: 'wrap',
  },
  input: {
    marginRight: '1em',
  },
}
