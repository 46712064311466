import { container } from '@k-mille/ui-components'
import Component from './actions'

const mapStore = (store, ownProps, { form, router }) => {
  const id = router.getParam('id')
  const expense = form(id).get()
  return {
    ...expense,
  }
}

export default container({
  mapStore,
  loader: 'id',
  intl: 'expense',
})(Component)
