export default {
  main: {
    display: 'grid',
    gridTemplateColumns: '100vw',
    gridTemplateRows: '90vh 10vh',
    gridTemplateAreas: '"content" "actions"',
  },
  content: {
    gridArea: 'content',
    display: 'flex',
    justifyContent: 'center',
  },
}
