import {
  add,
  toReview,
  notAccepted,
  drafts,
} from './reviews.operations'

export const load = (action, store, { graphql, router }) => {
  const [id] = store.ui.reviews.getKeys()
  if (id) {
    router.push('review', { id })
  } else {
    graphql.query(`
      ${toReview()}
      ${notAccepted()}
      ${drafts()}
    `)
  }
}

export const sortAndRedirect = (action, store, { router }) => {
  const currentId = router.getParam('id')
  const reviews = store.ui.reviews.getAsArray()

  store.ui.reviews.set(
    [...reviews]
      // a new expense is always at the top
      // all other expenses are sorted by their creation date
      .sort((expenseA, expenseB) => {
        if (expenseA.id === currentId) return -1
        if (expenseB.id === currentId) return 1
        return expenseB.dates.create - expenseA.dates.create
      }),
  )

  const [id] = store.ui.reviews.getKeys()
  if (!currentId && id) router.push('review', { id })
}

export const addReview = ({ payload }, store) => {
  store.ui.reviews.addOrUpdate(payload)
}

const dateToFileName = date => (
  date
    .toISOString()
    .replace('Z', '')
    .replace(/-/g, '')
    .replace('T', '_')
    .replace(/:/g, '')
    .replace('.', '_')
)
export const addExpense = async ({ payload }, store, { graphql }) => {
  let name = payload.name || dateToFileName(new Date(Date.now()))
  name = name.replace(/\.[^/.]+$/, '')

  const { data, type } = payload
  graphql.mutation(add(), {
    expense: {
      needRefund: false,
      document: {
        name,
        data,
        type,
      },
    },
  })
}

export const goToNewExpense = ({ payload }, store, { form, router }) => {
  form(payload.id).addOrUpdate(payload)
  router.push('edit', { id: payload.id })
}
