export default theme => ({
  main: {
    gridArea: 'actions',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
    width: '90vw',
    maxWidth: '64em',

    '& button': {
      fontSize: '1em',
    },
  },
  separator: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '2em',
    margin: '0 .5em',
    color: theme.grey,

    '&:before': {
      content: '"•"',
    },
  },
})
