import React from 'react'
import PropTypes from 'prop-types'
import { component } from '@k-mille/ui-components'
import Expense from '../../expense'
import Actions from './actions'
import styles from './edit.styles'

const Edit = ({
  classes,
  id,
}) => (
  <div className={classes.main}>
    <div className={classes.content}>
      <Expense id={id} edit />
    </div>
    <Actions />
  </div>
)

Edit.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string,
}

Edit.defaultProps = {
  id: undefined,
}

export default component({ styles })(Edit)
