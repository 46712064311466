import React from 'react'
import PropTypes from 'prop-types'
import { component, List } from '@k-mille/ui-components'
import styles from './expenses.styles'
import Header from '../../header'
import Expense from './expense'

const Expenses = ({ classes, expenses, loadMoreExpenses }) => (
  <div className={classes.main}>
    <Header className={classes.header} />
    <List className={classes.list} onListEnd={loadMoreExpenses}>
      {expenses.map(id => (
        <Expense
          key={id}
          id={id}
        />
      ))}
    </List>
  </div>
)

Expenses.propTypes = {
  classes: PropTypes.object,
  expenses: PropTypes.arrayOf(PropTypes.string),
  loadMoreExpenses: PropTypes.func,
}

Expenses.defaultProps = {
  classes: {},
  expenses: [],
  loadMoreExpenses: undefined,
}

export default component({ styles })(Expenses)
