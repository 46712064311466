import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { component, Button } from '@k-mille/ui-components'
import styles from './nav.styles'

const Add = ({
  classes,
  onFileSelected,
}) => {
  const fileRef = useRef(null)

  const onClick = () => {
    fileRef.current.click()
  }

  return (
    <div className={classes.main}>
      <Button
        name="ADD_EXPENSE"
        icon="add"
        action
        gradient
        onClick={onClick}
      />
      <input
        className={classes.input}
        type="file"
        ref={fileRef}
        accept="image/*,application/pdf"
        multiple={false}
        onChange={onFileSelected}
      />
    </div>
  )
}

Add.propTypes = {
  classes: PropTypes.object.isRequired,
  onFileSelected: PropTypes.func,
}

Add.defaultProps = {
  onFileSelected: undefined,
}

export default component({ styles })(Add)
