const EXPENSE_FIELDS = `
  id
  isAccepted
  needRefund
  document {
    url
    type
  }
  type
  draft
  description
  dates {
    create
  }
  creator {
    email
    name
    firstName
  }
  client {
    id
    name
  }
  user {
    id
    name
    firstName
    email
  }
  prices {
    total
    taxesRatio
    totalWithoutTaxes
    taxes
  }
`

/**
 * QUERY
 */
export const getExpense = id => `
  expense: expenses(id: "${id}")  {
    ${EXPENSE_FIELDS}
  }
`

export const getUsers = () => `
  users {
    id
    name
    firstName
    email
  }
`

export const getClients = () => `
  clients {
    id
    name
  }
`

export const replaceExpense = () => `
  ReplaceExpense ($expense: InputReplaceExpense!) {
    replaceExpense(input: $expense) {
      ${EXPENSE_FIELDS}
    }
  }
`

export const removeExpense = id => `
  { removeExpense(input: "${id}") }
`
