import { router } from '@k-redux-router/react-k-ramel'

const routes = {
  '/': {
    code: 'main',

    applicationName: 'Expenses',
    public: false,

    '/login': {
      code: 'login',
    },

    '/expenses': {
      code: 'expenses',
    },

    '/reviews': {
      code: 'reviews',
      '/:id': {
        code: 'review',
      },
    },

    '/edit/:id': {
      code: 'edit',
    },
  },
}

export default router({ routes })
