import { container } from '@k-mille/ui-components'
import Component from './total'

const mapStore = store => ({
  onKeyPress: (e) => {
    if (e.key === 'Enter') store.dispatch({ type: '@@ui>NEXT_EDIT_STEP', payload: { id: 1 } })
  },
})

export default container({
  intl: 'expense',
  mapStore,
})(Component)
