export default {
  main: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  content: {
    display: 'flex',
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  document: {
    maxWidth: '10vh',
    maxHeight: '10vh',
  },
  pdfButton: {
    position: 'absolute',
    top: '33%',
  },
  image: {
    marginRight: '1em',
    width: '10vh',
    height: '10vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#e8e8e8',
    border: '1px solid #cacaca',
  },
  creator: {
    color: 'red',
  },
  context: {
    display: 'flex',
  },
  notAccepted: {
    backgroundColor: 'red',
  },
  draft: {
    backgroundColor: 'grey',
  },
}
