import { when } from 'k-ramel'
import {
  load,
  addExpense,
  goToNewExpense,
  addReview,
  sortAndRedirect,
} from './reviews.reactions'

export default [
  when('@@krml/LISTENERS>ADDED>reviews')(load),
  when('@@image/IMAGE_REDUCED')(addExpense),
  when('@@ui/PDF>LOADED')(addExpense),
  when('@@graphql/addExpense>ENDED')(goToNewExpense),
  when('@@graphql/notAccepted>ENDED')(addReview),
  when('@@graphql/reviews>ENDED')(addReview),
  when('@@graphql/drafts>ENDED')(addReview),
  when('@@graphql/reviews,notAccepted,drafts>DONE')(sortAndRedirect),
]
