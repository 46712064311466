import { container } from '@k-mille/ui-components'
import listeners from './expenses.listeners'
import Component from './expenses'

const mapStore = store => ({
  expenses: store.data.expenses.getKeys(),
  loadMoreExpenses: () => store.dispatch({ type: '@@ui/SCROLL_END' }),
})

export default container({
  name: 'expenses',
  intl: 'expenses',
  isScreen: true,
  mapStore,
  listeners,
})(Component)
