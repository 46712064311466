import React from 'react'
import PropTypes from 'prop-types'
import { component, Card } from '@k-mille/ui-components'
import Document from './document'
import Creator from './creator'
import Info from './info'
import Edit from './edit'
import RefuseDescription from './refuseDescription'
import styles from './expense.styles'

const Expense = ({
  classes,
  id,
  edit,
  onSwipedLeft,
  onSwipedRight,
}) => (
  <div className={classes.main}>
    <Info id={id} className={classes.info} />
    <Card
      className={classes.card}
      onSwipedLeft={onSwipedLeft}
      onSwipedRight={onSwipedRight}
    >
      <Document id={id} />
    </Card>
    <Creator id={id} />
    {edit || <RefuseDescription id={id} />}
    {edit && <Edit id={id} className={classes.edit} />}
  </div>
)

Expense.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  edit: PropTypes.bool,
  onSwipedLeft: PropTypes.func,
  onSwipedRight: PropTypes.func,
}

Expense.defaultProps = {
  edit: false,
  onSwipedLeft: undefined,
  onSwipedRight: undefined,
}

export default component({ styles })(Expense)
