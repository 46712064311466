import React from 'react'
import PropTypes from 'prop-types'
import { component } from '@k-mille/ui-components'
import TaxesRatio from './taxesRatio'
import styles from './taxes.styles'

const taxes = [
  0.2,
  0.1,
  0.055,
  0,
]

const Taxes = ({
  classes,
  expenseId,
}) => (
  <div className={classes.main}>
    {taxes.map(taxesRatio => (
      <TaxesRatio
        key={taxesRatio}
        expenseId={expenseId}
        taxesRatio={taxesRatio}
      />
    ))}
  </div>
)

Taxes.propTypes = {
  classes: PropTypes.object.isRequired,
  expenseId: PropTypes.string,
}

Taxes.defaultProps = {
  expenseId: undefined,
}

export default component({ styles })(Taxes)
