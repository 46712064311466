export default theme => ({
  main: {
    display: 'flex',
    fontWeight: 'bold',
  },
  total: {
    color: theme.darkFont,
  },
  taxesRatio: {
    color: theme.grey,
  },
  separator: {
    display: 'flex',
    justifyContent: 'center',
    width: '1em',

    '&:before': {
      content: '"•"',
      color: theme.grey,
    },
  },
})
