import React from 'react'
import PropTypes from 'prop-types'
import { component } from '@k-mille/ui-components'
import styles from './client.styles'

const Client = ({
  classes,
  name,
}) => (
  <div className={classes.main}>
    {name}
  </div>
)

Client.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string,
}

Client.defaultProps = {
  name: undefined,
}

export default component({ styles })(Client)
