import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { component, Button } from '@k-mille/ui-components'
import styles from './document.styles'

const Document = ({
  classes,
  src,
  isPDF,
}) => (
  <Fragment>
    {isPDF && (
      <object
        data={src}
        type="application/pdf"
        className={classes.main}
      >
        <Button
          icon="pdf"
          name="expense_download_pdf"
          href={src}
        />
      </object>
    )}
    {isPDF || (
      <img
        className={classes.main}
        src={src}
        alt="expense document"
      />
    )}
  </Fragment>
)

Document.propTypes = {
  classes: PropTypes.object.isRequired,
  src: PropTypes.string,
  isPDF: PropTypes.bool,
}

Document.defaultProps = {
  src: undefined,
  isPDF: false,
}

export default component({ styles })(Document)
