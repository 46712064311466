import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { component, Button } from '@k-mille/ui-components'
import styles from './actions.styles'

const ExpenseActions = ({
  classes,
  messages,
  id,
  isAccepted,
  isOwner,
  draft,
  onClickRefuse,
}) => (
  <div
    className={classes.main}
  >
    {(isOwner || draft) && (
      <Fragment>
        <Button
          id={id}
          name="EDIT_EXPENSE"
        >
          {messages.edit}
        </Button>
        <div className={classes.separator} />
        <Button
          className={classes.remove}
          id={id}
          name="REMOVE_EXPENSE"
        >
          {messages.remove}
        </Button>

      </Fragment>
    )}
    {!isAccepted && !isOwner && (
      <Fragment>
        <Button
          id={id}
          onClick={onClickRefuse}
        >
          {messages.refuse}
        </Button>
        <div className={classes.separator} />
        <Button
          id={id}
          name="ACCEPT_EXPENSE"
        >
          {messages.accept}
        </Button>
      </Fragment>
    )}
  </div>
)

ExpenseActions.propTypes = {
  classes: PropTypes.object.isRequired,
  messages: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  isAccepted: PropTypes.bool,
  isOwner: PropTypes.bool,
  draft: PropTypes.bool,
  onClickRefuse: PropTypes.func,
}

ExpenseActions.defaultProps = {
  isAccepted: false,
  isOwner: false,
  draft: false,
  onClickRefuse: undefined,
}

export default component({ styles })(ExpenseActions)
