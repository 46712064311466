import React from 'react'
import PropTypes from 'prop-types'
import { component, Input } from '@k-mille/ui-components'
import styles from './needRefund.styles'

const NeedRefund = ({
  classes,
  messages,
  expenseId,
}) => (
  <div className={classes.main}>
    <Input
      label={messages.needRefund}
      className={classes.input}
      type="checkbox"
      formName={expenseId}
      name="needRefund"
    />
  </div>
)

NeedRefund.propTypes = {
  classes: PropTypes.object.isRequired,
  messages: PropTypes.object.isRequired,
  expenseId: PropTypes.string,
}

NeedRefund.defaultProps = {
  expenseId: undefined,
}

export default component({ styles })(NeedRefund)
