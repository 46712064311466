import React from 'react'
import PropTypes from 'prop-types'
import { component } from '@k-mille/ui-components'
import styles from './refuseDescription.styles'

const RefuseDescription = ({
  classes,
  refuseDescription,
}) => {
  if (!refuseDescription) return null
  return (
    <div
      className={classes.main}
    >
      {refuseDescription}
    </div>
  )
}

RefuseDescription.propTypes = {
  classes: PropTypes.object.isRequired,
  refuseDescription: PropTypes.string,
}

RefuseDescription.defaultProps = {
  refuseDescription: '',
}

export default component({ styles })(RefuseDescription)
