export default {
  main: {
    display: 'grid',
    gridTemplateColumns: '100vw',
    gridTemplateRows: '6em auto 10vh 10vh',
    gridTemplateAreas: '"header" "content" "actions" "nav"',
  },
  content: {
    gridArea: 'content',
    display: 'flex',
    justifyContent: 'center',
  },
  allDone: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
  },
  next: {
    height: '3em',
    alignSelf: 'center',
    '@media screen and (max-width: 640px)': {
      display: 'none',
    },
  },
}
