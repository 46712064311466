import { when } from 'k-ramel'
import {
  redirectToReviews,
  addPasteListener,
  removePasteListener,
  pasteImage,
  reduceImage,
  setExpense,
} from './main.reactions'

export default [
  when('@@router/ROUTE_FOUND', ({ payload }) => payload.route.code === 'main')(redirectToReviews),

  when('@@krml/INIT')(addPasteListener),
  when('@@krml/LISTENERS>REMOVING>main')(removePasteListener),

  when('@@ui/ON_PASTE')(pasteImage),
  when('@@ui/IMAGE>LOADED')(reduceImage),

  when('@@graphql/expense>ENDED')(setExpense),
  when('@@graphql/addExpense>ENDED')(setExpense),
]
