import React from 'react'
import PropTypes from 'prop-types'
import { component } from '@k-mille/ui-components'
import styles from './project.styles'

const Project = ({
  classes,
  name,
}) => (
  <div className={classes.main}>
    {name}
  </div>
)

Project.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string,
}

Project.defaultProps = {
  name: undefined,
}

export default component({ styles })(Project)
