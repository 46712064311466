const editHeight = 5
const infoHeight = 3

export default theme => ({
  main: {
    position: 'relative',
  },
  card: {
    position: 'relative',
    width: '85vw',
    maxWidth: '64em',
    outline: 'none',
    userSelect: 'none',
    textDecoration: 'none',
    overflowY: 'auto',
    height: ({ edit }) => `calc(100% - ${infoHeight + (edit ? editHeight : 0)}em)`,
    border: ({ draft }) => `solid 1px ${(draft ? theme.warning : theme.lightGrey)}`,
  },
  info: {
    height: `${infoHeight}em`,
  },
  edit: {
    height: `${editHeight}em`,
  },
})
