import { lastExpenses, moreExpenses } from './expenses.operations'

const mapExpense = store => expense => ({
  ...expense,
  isOwner: expense.creator.email === store.data.profile.info.get().email,
})

export const load = (action, store, { graphql }) => {
  graphql.query(lastExpenses())
}

export const loadMore = (action, store, { graphql }) => {
  const [lastExpense] = store.data.expenses.getAsArray().slice(-1)
  graphql.query(moreExpenses(10, lastExpense && lastExpense.id))
}

export const set = ({ payload }, store) => {
  store.data.expenses.set(payload.map(mapExpense(store)))
}

export const add = ({ payload }, store) => {
  if (payload.length === 0) return
  store.data.expenses.add(payload.map(mapExpense(store)))
}
