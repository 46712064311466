export default theme => ({
  main: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: ({ needRefund }) => (needRefund ? theme.warning : theme.background),
    color: ({ needRefund }) => (needRefund ? theme.background : theme.grey),
    fontSize: '80%',
    borderRadius: '4px',
    padding: '.5em',
    position: 'absolute',
    top: '5em',
    right: '1em',
    transition: 'opacity .25s linear',
    opacity: 0.9,
    '&:hover': {
      opacity: 0.2,
    },
  },
  icon: {
    marginRight: '.5em',
    fontSize: '1.5em',
  },
  separator: {
    display: 'flex',
    justifyContent: 'center',
    width: '1em',

    '&:before': {
      content: '"•"',
    },
  },
})
