import { container } from '@k-mille/ui-components'
import Component from './nav'

const fileToBase64 = file => new Promise((resolve) => {
  // eslint-disable-next-line no-undef
  const reader = new FileReader()
  // Read file content on file loaded event
  reader.onload = (event) => {
    resolve(event.target.result)
  }
  // Convert data to base64
  reader.readAsDataURL(file)
})

const mapStore = store => ({
  onFileSelected: async (e) => {
    const [file] = e.target.files
    const { type, name } = file

    if (/image.*/.test(type)) store.dispatch({ type: '@@ui/IMAGE>LOADED', payload: file })

    if (type === 'application/pdf') {
      store.dispatch({
        type: '@@ui/PDF>LOADED',
        payload: {
          data: (await fileToBase64(file)).replace(/data:.*;base64,/, ''),
          type,
          name,
        },
      })
    }
  },
})

export default container({
  mapStore,
})(Component)
