import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { component, Input } from '@k-mille/ui-components'
import styles from './clients.styles'

const Clients = ({
  classes,
  expenseId,
  project,
  clients,
  users,
  onClick,
}) => (
  <div className={classes}>
    <div className={classes.secondary}>
      {clients.map(({ id, name }) => (
        <Input
          key={id}
          className={cn(classes.input, classes.clients)}
          onClick={onClick}
          name="clients"
          type="radio"
          formName={expenseId}
          value={`client-${id}`}
          label={name}
        />
      ))}
      {users.map(({ id, name, firstName }) => (
        <Input
          key={id}
          className={cn(classes.input, classes.users)}
          onClick={onClick}
          name="clients"
          type="radio"
          formName={expenseId}
          value={`user-${id}`}
          label={`${firstName} ${name}`}
        />
      ))}
    </div>
    <div className={classes.primary}>
      <Input
        className={classes.input}
        onClick={onClick}
        type="radio"
        formName={expenseId}
        name="type"
        value="PROJECT"
        label={project.name}
      />
      <Input
        className={classes.input}
        name="type"
        type="radio"
        formName={expenseId}
        value="CLIENT"
        label="Clients"
      />
      <Input
        className={classes.input}
        name="type"
        type="radio"
        formName={expenseId}
        value="USER"
        label="Users"
      />
    </div>
  </div>
)

Clients.propTypes = {
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  expenseId: PropTypes.string,
  project: PropTypes.object,
  clients: PropTypes.array,
  users: PropTypes.array,
}

Clients.defaultProps = {
  expenseId: undefined,
  project: {},
  clients: [],
  users: [],
}

export default component({ styles })(Clients)
