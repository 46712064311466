import React from 'react'
import PropTypes from 'prop-types'
import { component, Input } from '@k-mille/ui-components'
import styles from './taxesRatio.styles'

const TaxesRatio = ({
  classes,
  messages,
  expenseId,
  taxesRatio,
  onClick,
}) => (
  <Input
    className={classes.main}
    name="taxesRatio"
    type="radio"
    formName={expenseId}
    value={taxesRatio}
    label={messages.taxesRatio}
    onClick={onClick}
  />
)

TaxesRatio.propTypes = {
  classes: PropTypes.object.isRequired,
  messages: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  expenseId: PropTypes.string,
  taxesRatio: PropTypes.number,
}

TaxesRatio.defaultProps = {
  expenseId: undefined,
  taxesRatio: 0,
}

export default component({ styles })(TaxesRatio)
