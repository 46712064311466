import React from 'react'
import PropTypes from 'prop-types'
import { component, Icon } from '@k-mille/ui-components'
import styles from './creator.styles'

const Creator = ({
  classes,
  messages,
  email,
  name,
  firstName,
  needRefund,
}) => (
  <div
    className={classes.main}
    title={email}
  >
    {needRefund && <Icon className={classes.icon}>money</Icon>}
    {needRefund || <Icon className={classes.icon}>money_off</Icon>}
    {firstName}
    {' '}
    {name}
    <div className={classes.separator} />
    {messages.dates}
  </div>
)

Creator.propTypes = {
  classes: PropTypes.object.isRequired,
  messages: PropTypes.object.isRequired,
  email: PropTypes.string,
  name: PropTypes.string,
  firstName: PropTypes.string,
  needRefund: PropTypes.bool,
}

Creator.defaultProps = {
  email: undefined,
  name: undefined,
  firstName: undefined,
  needRefund: false,
}

export default component({ styles })(Creator)
