import React from 'react'
import PropTypes from 'prop-types'
import { component } from '@k-mille/ui-components'
import Expense from '../../../expense'
import styles from './review.styles'

const Review = ({
  id,
  onSwipedLeft,
  onSwipedRight,
}) => (
  <Expense
    id={id}
    onSwipedLeft={onSwipedLeft}
    onSwipedRight={onSwipedRight}
  />
)

Review.propTypes = {
  id: PropTypes.string,
  onSwipedLeft: PropTypes.func,
  onSwipedRight: PropTypes.func,
}

Review.defaultProps = {
  id: undefined,
  onSwipedLeft: undefined,
  onSwipedRight: undefined,
}

export default component({ styles })(Review)
