import { container } from '@k-mille/ui-components'
import Component from './document'

const mapStore = (store, { id }, { form }) => {
  const { url, type } = form(id).get('document')
  return {
    src: url,
    isPDF: type === 'application/pdf',
  }
}

export default container({
  mapStore,
  intl: 'expenses',
})(Component)
