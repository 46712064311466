/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { component, Button } from '@k-mille/ui-components'
import styles from './header.styles'

const Header = ({ className, classes }) => (
  <div className={cn(className, classes.main)}>
    <div className={classes.title}>
      Expenses
    </div>

    <div className={classes.links}>
      <Button
        toRouteCode="expenses"
      >
        list
      </Button>
      <Button
        toRouteCode="reviews"
      >
        reviews
      </Button>
    </div>
  </div>
)

Header.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object,
}

Header.defaultProps = {
  className: undefined,
  classes: {},
}

export default component({ styles })(Header)
