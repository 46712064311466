/**
 * FIELDS
 */

/**
 * QUERY
 */
export const getExpense = id => `
  expense: expenses(id: "${id}") {
    id
    draft
    isAccepted
    refuseDescription
    description
    document {
      url
      type
    }
    needRefund
    type
    dates {
      create
    }
    creator {
      email
      name
      firstName
    }
    client {
      id
      name
    }
    user {
      id
      name
      firstName
      email
    }
    prices {
      total
      taxesRatio
    }
  }
`

export const reviewExpense = () => `
  ReviewExpense ($expense: InputReviewExpense!) {
    reviewExpense(input: $expense) {
      id
      isAccepted
    }
  }
`

export const removeExpense = id => `
  { removeExpense(input: "${id}") }
`
