const EXPENSE_FIELDS = `
  id
  draft
  isAccepted
  refuseDescription
  needRefund
  document {
    url
    type
  }
  dates {
    create
  }
  creator {
    email
    name
    firstName
  }
  prices {
    total
    taxesRatio
    totalWithoutTaxes
    taxes
  }
`

/**
 * QUERY
 */
export const toReview = () => `
  reviews: expenses(review: true) {
    id
    dates {
      create
    }
  }
`
export const notAccepted = () => `
  notAccepted: expenses(notAccepted: true) {
    id
    dates {
      create
    }
  }
`

export const drafts = () => `
  drafts: expenses(draft: true) {
    id
    dates {
      create
    }
  }
`

export const add = () => `
  AddExpense ($expense: InputExpense!) {
    addExpense(input: $expense) {
      ${EXPENSE_FIELDS}
    }
  }
`
