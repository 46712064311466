export default {
  main: {
    gridArea: 'actions',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0 auto',
    width: '90vw',
    maxWidth: '64em',
  },
}
