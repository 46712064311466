export const redirectToReviews = (action, store, { router }) => {
  router.push('reviews')
}

// ref to paste function, it's used to remove listener.
let pasteEvent

export const addPasteListener = (action, store, { window }) => {
  pasteEvent = e => store.dispatch({ type: '@@ui/ON_PASTE', payload: e })
  window.addEventListener('paste', pasteEvent)
}

export const removePasteListener = (action, store, { window }) => {
  window.removeEventListener('paste', pasteEvent)
}

export const pasteImage = ({ payload }, store) => {
  if (!payload || !payload.clipboardData || !payload.clipboardData.items) return

  const { items } = payload.clipboardData
  for (let i = 0; i < items.length; i += 1) { // items is a DataTransferItemList : https://developer.mozilla.org/en-US/docs/Web/API/DataTransferItemList
    const item = items[i]
    if (/image.*/.test(item.type)) {
      store.dispatch({ type: '@@ui/IMAGE>LOADED', payload: item.getAsFile() })
    }
  }
}

export const reduceImage = ({ payload }, store, { image }) => {
  image.reduce(payload)
}

export const setExpense = ({ payload = [] }, store, { form }) => {
  const mapExpense = ({
    client,
    user,
    prices,
    ...rest
  }) => ({
    ...rest,
    ...prices,
    user,
    client,
    clients: (client && `client-${client.id}`) || (user && `user-${user.id}`),
  })

  const [expense] = [].concat(payload)
  form(expense.id).set(
    mapExpense(expense),
  )
}
