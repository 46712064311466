import React from 'react'
import PropTypes from 'prop-types'
import { component } from '@k-mille/ui-components'
import styles from './user.styles'

const User = ({
  classes,
  email,
  name,
  firstName,
}) => (
  <div
    className={classes.main}
    title={email}
  >
    {firstName}
    {' '}
    {name}
  </div>
)

User.propTypes = {
  classes: PropTypes.object.isRequired,
  email: PropTypes.string,
  name: PropTypes.string,
  firstName: PropTypes.string,
}

User.defaultProps = {
  email: undefined,
  name: undefined,
  firstName: undefined,
}

export default component({ styles })(User)
