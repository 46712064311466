import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { component } from '@k-mille/ui-components'
import Client from './client'
import Prices from './prices'
import Project from './project'
import User from './user'
import styles from './info.styles'

const getTypeComponent = (type) => {
  switch (type) {
    case 'USER': return User
    case 'CLIENT': return Client
    default: return Project
  }
}

const Info = ({
  classes,
  className,
  id,
  type,
}) => (
  <div
    className={cn(className, classes.main)}
  >
    <Prices id={id} />
    {React.createElement(getTypeComponent(type), { id })}
  </div>
)

Info.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string,
}

Info.defaultProps = {
  className: undefined,
  id: undefined,
  type: undefined,
}

export default component({ styles })(Info)
