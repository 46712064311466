import {
  getExpense,
  reviewExpense,
  removeExpense,
} from './review.operations'

export const load = (action, store, { graphql, router, form }) => {
  const expenseId = router.getParam('id')
  if (!form(expenseId).get('id')) {
    graphql.query(`
    ${getExpense(expenseId)}
  `)
  }
}

export const remove = (action, store, { router, form, graphql }) => {
  const id = router.getParam('id')
  graphql.mutation(removeExpense(id))
  form(id).reset()
}

export const review = isAccepted => (action, store, { graphql, router, form }) => {
  const id = router.getParam('id')
  const refuseDescription = form(id).get('refuseDescription')
  const expense = { id, isAccepted, refuseDescription }
  graphql.mutation(reviewExpense(), { expense })

  store.ui.modalId.reset()

  store.ui.reviews.set(store.ui.reviews.getAsArray().slice(1))
  const [nextId] = store.ui.reviews.getKeys()
  if (nextId) router.push('review', { id: nextId })
  else router.push('reviews')
}

export const next = isRemove => (action, store, { router }) => {
  const queue = [...store.ui.reviews.getAsArray()]
  const first = queue.shift()
  if (isRemove) store.ui.reviews.set(queue)
  else store.ui.reviews.set([...queue, first])

  const [nextId] = store.ui.reviews.getKeys()
  router.push('review', { id: nextId })
}

export const previous = (action, store, { router }) => {
  const queue = [...store.ui.reviews.getAsArray()]
  const last = queue.pop()
  store.ui.reviews.set([last, ...queue])

  const [nextId] = store.ui.reviews.getKeys()
  router.push('review', { id: nextId })
}

export const goToEdit = ({ payload }, store, { router }) => {
  router.push('edit', payload)
}
