import { Login } from '@k-mille/ui-login'
import Expenses from './expenses'
import Reviews from './reviews'
import Edit from './edit'

export default [
  Login,
  Expenses,
  Reviews,
  Edit,
]
