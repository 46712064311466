import React from 'react'
import PropTypes from 'prop-types'
import { component, Card } from '@k-mille/ui-components'
import styles from './expense.styles'

const ExpenseLoading = ({
  classes,
}) => (
  <div className={classes.main}>
    <div className={classes.info} />
    <Card className={classes.card}>
      <Card.Content loading />
    </Card>
  </div>
)

ExpenseLoading.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default component({ styles })(ExpenseLoading)
